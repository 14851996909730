<section class="maintenance-sec">
    <div>
        <div class="snow snow--near"></div>
        <div class="snow snow--near snow--alt"></div>
        
        <div class="snow snow--mid"></div>
        <div class="snow snow--mid snow--alt"></div>
        
        <div class="snow snow--far"></div>
        <div class="snow snow--far snow--alt"></div>
      
    <div class="center-content">
        <div class="maintenance-container">
            <h1>oops!</h1>
            <h4>maintenance in process</h4>
            <h2>we will be back soon</h2>
            <div class="social m-t-20">
                <ul>
                    <li>
                        <a href="#">
                            <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="fa fa-google center-content" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="fa fa-instagram center-content" aria-hidden="true"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</section>

